import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './rem.js'
import './style/common.scss'
// import echarts from 'echarts'
import common from './utils/common'
Vue.prototype.$common = common
// import Bridge from './utils/bridge.js'
// Vue.prototype.$bridge = Bridge
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import {post,get} from './utils/http'
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

import Print from 'vue-print-nb'
Vue.use(Print);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
