const config = {
    debug: false,
    // 测试地址
    // serviceHost: 'http://192.168.0.65:7970',

    // 正式
    serviceHost: 'https://plapi.cdjkyl.com',
    apiParam: {
      
    },
    defaultPageSize: 10
}
  
export default config