import axios from 'axios'
import config from '../config'
import router from '@/router'
import Cookies from 'js-cookie'
console.info(config.serviceHost)
// create an axios instance
// const service = axios.create({
  axios.defaults.baseURL = config.serviceHost,
  axios.defaults.withCredentials = true, // send cookies when cross-domain requests
  axios.defaults.timeout = 50000 // request timeout
// })
// application/x-www-form-urlencoded
// request interceptor
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    const token = Cookies.get("Admin-Token");
    if (token){
      config.headers.Authorization = token
    }
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.info(res)
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '200') {
      if (res.code === '401') {
        if (res.message === '验证码错误' || res.message === '账号或密码错误') {

        } else {
          // Message({
          //   message: res.message || 'Error',
          //   type: 'error',
          //   duration: 5 * 1000
          // })
        }
      }
      if (res.code === '401') {
        // store.dispatch('user/resetToken')
        Cookies.remove('Admin-Token');
        router.replace({
          path: '/login'
        })
        return res
      }
      if (res.code === '500') {
      
        return res
      }
    
      // return Promise.reject(new Error(`${res.message}` || '网络错误'))
    } else {
      return res
    }
  },
  error => {
    // const text = common.isEmpty(error.response) ? '网络连接超时' : error.response.data.message
    return Promise.reject(error)
  }
)
// export default service;
// 封装xiaos请求  封装axios里的get
export function get(url, params={}, headers={}){
  return new Promise((resolve, reject) => {
    axios.get(config.serviceHost+''+url,{
      params: params,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    })
    .then(response => {
      // console.info(response,'response.data')
      resolve(response);
    })
    .catch(err => {
      reject(err)
    })
  })
 }

// 封装xiaos请求  封装axios里的post
export function post(url, data = {}){
  return new Promise((resolve, reject) => {
    axios.post(config.serviceHost+''+url, data)
    .then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}
