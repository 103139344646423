<template>
    <div class="index">
        <van-nav-bar class="title-style" title="成都金科玉律收费管理系统" fixed placeholder safe-area-inset-top />
        <div class="total" @click="linkUrl('/settletoday')">
            <div class="total-num">
                <div class="num">费用总计</div>
                <div class="symbol"><span>今日结算</span> &#155</div>
            </div>
            <div class="total-money"><span>￥</span>{{todayMoney?todayMoney.formatMoney():"0.00"}}</div>
            <div class="total-branch">
                <div class="left">
                    <div class="num-style"><span>￥</span>{{todayAndAll.settledMoney?todayAndAll.settledMoney.formatMoney():"0.00"}}</div>
                    <div class="text">已结算金额</div>
                </div>
                <div class="right">
                    <div class="num-style"><span>￥</span>{{todayAndAll.unsettledMoney?todayAndAll.unsettledMoney.formatMoney():"0.00"}}</div>
                    <div class="text">待结算金额</div>
                </div>
            </div>
        </div>

        <div class="service">
            <div class="title">系统服务费分析表</div>
            <!-- <div class="btn-wrapper">
                <span class="service-btn" :class="check=='day'?'active':''" @click='changeTime("day",6)'>日报</span>
                <span class="service-btn" :class="check=='month'?'active':''" @click='changeTime("month",6)'>月报</span>
                <span class="service-btn" :class="check=='year'?'active':''" @click='changeTime("year",3)'>年报</span>
            </div> -->
            <div>
                <div id="myChart" :style="{width: '100%', height: '300px'}"></div>
            </div>
        </div>

        <div class="statistics">
            <div class="title">系统服务费统计 <span>¥{{formFeeTotal.formatMoney()}}</span></div>
            <div class="item-box">
                <div class="item" @click="linkUrl('/court')">
                    <div class="left">
                        <img src="../assets/court.png" alt="">
                        <span class="custom-title">法院系统服务费</span>
                    </div>
                    <div class="right">¥{{formFee.courtPlatformFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
                <div class="item" @click="linkUrl('/lawfirm')">
                    <div class="left">
                        <img src="../assets/lawfirm.png" alt="">
                        <span class="custom-title">律所系统服务费</span>
                    </div>
                    <div class="right">¥{{formFee.lawyerPlatformFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
                <div class="item" @click="linkUrl('/service')">
                    <div class="left">
                        <img src="../assets/service.png" alt="">
                        <span class="custom-title">服务机构系统服务费</span>
                    </div>
                    <div class="right">¥{{formFee.otherPlatformFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
                <div class="item" @click="linkUrl('/mediate')">
                    <div class="left">
                        <img src="../assets/mediate.png" alt="">
                        <span class="custom-title">调解系统服务费</span>
                    </div>
                    <div class="right">¥{{formFee.mediatePlatformFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
                <div class="item" @click="linkUrl('/plaintiff')">
                    <div class="left">
                        <img src="../assets/plaintiff.png" alt="">
                        <span class="custom-title">原告系统服务费</span>
                    </div>
                    <div class="right">¥{{formFee.plaintiffPlatformFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
                <div class="item" @click="linkUrl('/signature')">
                    <div class="left">
                        <img src="../assets/signature.png" alt="">
                        <span class="custom-title">签章费用</span>
                    </div>
                    <div class="right">¥{{formFee.cfcaFee.formatMoney()}}<span class="symbol">&#155</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { button, Toast ,NavBar ,Cell, CellGroup } from 'vant';
import Cookies from 'js-cookie'
export default {
    name:"index",
    data(){
        return{
            check:"month",
            time:[],
            xAxis_list: '',
            series_list: [],
            formFee:{
                courtPlatformFee:0,
                lawyerPlatformFee:0,
                otherPlatformFee:0,
                mediatePlatformFee:0,
                plaintiffPlatformFee:0,
                cfcaFee:0
            },
            formFeeTotal:0,
            todayAndAll:{},
            todayMoney:''
        }
    },
    methods: {
        changeTime(value,num){
            this.check = value
            this.$get(`/finance/api/fee/statisticsPeriodFee`,{unit:value,before:num}).then(response => {
                if (response.code === '200') {
                    this.time = response.data
                    this.SetEcharData()
                }
            })
        },
        getToday(){
            this.$get(`/finance/api/fee/statisticsTodayAndAll`).then(response => {
                if (response.code === '200') {
                    this.todayAndAll = response.data
                    this.todayMoney = this.$common.float_add(response.data.settledMoney,response.data.unsettledMoney)
                }
            })
        },
        getList(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/statisticsByFeeType`).then(response => {
                if (response.code === '200') {
                    this.formFee.courtPlatformFee = response.data.courtPlatformFee
                    this.formFee.lawyerPlatformFee = response.data.lawyerPlatformFee
                    this.formFee.otherPlatformFee = response.data.otherPlatformFee
                    this.formFee.mediatePlatformFee = response.data.mediatePlatformFee
                    this.formFee.plaintiffPlatformFee = response.data.plaintiffPlatformFee
                    this.formFee.cfcaFee = response.data.cfcaFee 
                    for(var k in response.data){
                        this.formFeeTotal = this.$common.float_add(this.formFeeTotal,response.data[k])
                    }
                    Toast.clear();
                }
            })
        },
        linkUrl(url){
            this.$router.push({path:url})
        },
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            // 绘制图表
            myChart.setOption({
                // title: { text: '在Vue中使用echarts' },
                tooltip: {},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '{b}<br />系统服务费：{c0}万'
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgb(228,142,52)'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#000',
                            align: 'center',
                        },
                        rotate:38,//角度顺时针计算的
                        margin: 30
                    },
                    axisTick: {show: false},
                    data: this.xAxis_list
                },
                yAxis: {
                    type: 'value',
                    axisLabel: { // x轴的坐标文字
                        show: false,
                    },
                    // max:100,
                    axisLine: {
                        show: false,
                   
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: { // 坐标在grid区域的分割线
                       show:false
                    }
                },
                series: this.series_list
            });
        },
        SetEcharData() {
            this.xAxis_list = []
            this.series_list = []
            const returnAmount = {
                name: '系统服务费',
                type: 'bar',
                label: {
                    normal: {
                        show: true, // 显示数字
                        formatter: '{c}万',
                        position: 'top'
                    }
                },
                itemStyle:{
                    color: '#DEB182',
                    emphasis: {
                        color:'#E48E34',
                        shadowColor: 'rgba(228, 142, 52,1)'
                    }
                },
                barWidth: '50%', // 柱子宽度
                data: []
            }
            for (const val_v of this.time) {
                // if (val === 'week') {
                //     this.xAxis_list.push(`${val_v.returnDateWeekStart.replace(/-/g, '/').slice(5, 10)}-${val_v.returnDateWeekEnd.replace(/-/g, '/').slice(5, 10)}`)
                // } else {
                    // this.xAxis_list.push(val_v.returnDate.replace(/-/g, '/').slice(5, 10))
                    this.xAxis_list.push(val_v.cmonth)
                // }
                val_v.totalMoney = parseFloat(this.$common.float_div(val_v.totalMoney,10000)).toFixed(2)
                returnAmount.data.push(val_v.totalMoney)
            }
            this.series_list.push(returnAmount)
            this.$nextTick(() => {
                this.drawLine()
            })
        },
        async getPlaCode() {
            await this.$get(`/plaintiff/api/code/getPlaCode`).then(res => {
                if (res.code === '200') {
                    this.$store.dispatch('SetPlacode', res.data)
                    console.info(res.data)
                }
            })
        }
    },
    mounted() {
        this.getToday()
        this.changeTime("year",3)
        this.getList()
        this.getPlaCode()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [NavBar.name]: NavBar,
    }
}
</script>

<style lang="scss" scoped>
    .index{
        .title{
            font-size: 16px;
            color: #000;
            font-weight: bold;
            span{
                color: #DB9245;
                font-size: 15px;
                float: right;
                margin-top: 4px;
            }
        }
        .title-style{
            .van-nav-bar__title{
                font-weight: bold!important;
                color: #000!important;
            }
        }
        .total{
            padding: 20px 15px;
            margin: 10px 15px;
            background: #E48E34;
            border-radius: 10px;
            .total-num{
                font-size:14px;
                text-align: left;
                color: #FFCE9A;
                overflow: hidden;
                .num{
                    float: left;
                    span{
                        color: #FEFEFE;
                    }
                }
                .symbol{
                    float: right;
                    color: #FFCE9A;
                    font-size: 20px;
                    margin-top: -4px;
                    span{
                        font-size:12px;
                    }
                }
            }
            .total-money{
                text-align: center;
                color: #fff;
                padding: 40px 0;
                font-size: 27px;
                span{
                    font-size: 18px;
                }
            }
            .total-branch{
                overflow: hidden;
                text-align: center;
                color: #fff;
                .left{
                    float: left;
                    width: 50%;
                    padding: 5px 0;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 2px;
                        height: 100%;
                        background: #FFC185;
                        right: 0;
                        top: 0;
                    }
                }
                .right{
                    padding: 5px 0;
                    float: right;
                    width: 50%;
                }
                .num-style{
                    font-size: 16px;
                    margin-bottom: 5px;
                    span{
                        font-size: 12px;
                    }
                }
                .text{
                    font-size: 10px;
                    color: #FFE5C9;
                }
            }
        }
        .service{
            background: #fff;
            padding: 20px 15px;
            .btn-wrapper{
                padding-top: 20px;
                .service-btn{
                    display: inline-block;
                    width: 54px;
                    height: 23px;
                    line-height: 25px;
                    margin-right: 10px;
                    font-size: 12px;
                    color: #858585;
                    border: 1px solid #999;
                    text-align: center;
                    border-radius: 3px;
                    &.active{
                        background: #FF8200;
                        border-color: #FF8200;
                        color: #fff;
                    }
                }
            }
            
        }
        .statistics{
            background: #fff;
            padding: 20px 15px;
            margin: 10px 0;
            .item-box{margin-top: 10px;}
            .item{
                overflow: hidden;
                width: 100%;
                padding: 15px 0;
                border-bottom: 1px solid #E8E8E8;
                .left{
                    float: left;
                }
                .right{
                    float: right;
                    line-height: 37px;
                    font-size: 15px;
                    color: #535353;
                    .symbol{
                        font-size: 20px;
                        margin-left: 10px;
                    }
                }
                img{
                    width: 40px;
                    height: 40px;
                    vertical-align: middle;
                }
                .custom-title {
                    margin-left: 6px;
                    font-size: 14px;
                    color: #424242;
                }
                .van-cell{
                    padding: 0;
                }
            }
        }
    }
</style>