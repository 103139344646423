import Vue from "vue";
import VueRouter from "vue-router";
import cookies from 'js-cookie'
import index from "../views/index.vue";

Vue.use(VueRouter);
const isLogin = (to, from, next) => {
  if (cookies.get("Admin-Token")) {
      next();
  } else {
    next("/login");
  }

};
const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    beforeEnter: isLogin
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/index",//首页
    name: "index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/court",//法院系统服务费
    name: "court",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "court" */ "../views/court/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/court/detail",//法院系统服务费结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/court/detail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/lawfirm",//律所系统服务费
    name: "lawfirm",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lawfirm" */ "../views/lawfirm/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/lawfirm/detail",//律所系统服务费结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/lawfirm/detail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/lawfirm/serviceDetail",//律所系统服务费服务详情
    name: "serviceDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "serviceDetail" */ "../views/lawfirm/serviceDetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/common/batch",//律所系统服务费服务详情批次号
    name: "batch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "batch" */ "../views/common/batch.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/common/person",//律所系统服务费服务详情批次号个人信息
    name: "person",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "person" */ "../views/common/person.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/service",//服务机构系统服务费
    name: "service",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/service/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/service/detail",//服务机构系统服务费结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/service/detail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/service/serviceDetail",//服务机构系统服务费结算详情
    name: "serviceDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "serviceDetail" */ "../views/service/serviceDetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/signature",//签章费用
    name: "signature",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signature" */ "../views/signature/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/signature/serviceDetail",//签章费用服务详情
    name: "serviceDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "serviceDetail" */ "../views/signature/serviceDetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/signature/detail",//签章费用结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/signature/detail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/mediate",//调解系统服务费
    name: "mediate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mediate" */ "../views/mediate/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/mediate/serviceDetail",//调解系统服务费服务详情
    name: "serviceDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "serviceDetail" */ "../views/mediate/serviceDetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/mediate/detail",//调解系统服务费结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/mediate/detail.vue"),
    beforeEnter: isLogin
  },

  
  {
    path: "/settletoday",//今日结算记录
    name: "settletoday",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settletoday" */ "../views/settletoday.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/settletoday/settletodaydetail",//今日结算记录详情
    name: "settletodaydetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settletodaydetail" */ "../views/settletodaydetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/plaintiff",//原告机构系统服务费
    name: "plaintiff",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "plaintiff" */ "../views/plaintiff/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/plaintiff/detail",//原告机构系统服务费结算详情
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/plaintiff/detail.vue"),
    beforeEnter: isLogin
  },


  {
    path: "/middlepage",
    name: "middlepage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "middlepage" */ "../views/middlepage/index.vue"),
  },
  {
    path: "/success",
    name: "success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/middlepage/success.vue"),
  },
  {
    path: "/fail",
    name: "fail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fail" */ "../views/middlepage/fail.vue"),
  },
  {
    path: "/textpage",
    name: "textpage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "textpage" */ "../views/middlepage/textpage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
